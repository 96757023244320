import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'

class Apps extends Component {
  constructor(props) {
    super(props)

    let {
      pageContext: { locale },
      location: { pathname },
    } = this.props

    if (typeof window !== 'undefined') {
      const { userAgent } = window.navigator
      let ua = userAgent.toLowerCase()

      if (pathname === `/${locale}/apps/` || pathname === `/${locale}/apps`) {
        if (ua.indexOf('macintosh') !== -1) {
          pathname = `/${locale}/apps/osx`
        } else if (ua.indexOf('iphone') !== -1) {
          pathname = `/${locale}/apps/ios`
        } else if (ua.indexOf('android') !== -1) {
          pathname = `/${locale}/apps/android`
        } else if (ua.indexOf('windows') !== -1) {
          pathname = `/${locale}/apps/windows`
        } else {
          pathname = `/${locale}/apps/windows`
        }
      }
      const newUrl = withPrefix(`/${pathname}`)

      window.location.replace(newUrl)
    }
  }

  render() {
    return <div />
  }
}

Apps.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

export default Apps
